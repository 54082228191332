/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import HomeHero from 'components/general/HomeHero';
import RolexWatch from 'components/general/RolexWatch';
import NativeContent from 'components/cms/NativeContent';
import deps from 'dependencies';
import { fetchPromiseAll } from 'appdir/components/general/Util';
import axios from 'axios';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import MeasurementUtils from 'appdir/lib/analytics';
import Template from 'components/Template';

import OnCourt from 'components/general/OnCourtSchedule';
import AdUnitLazy from 'components/general/AdUnitLazy';
import HomeContentWrapper from 'appdir/components/cms/HomeContent/HomeContentWrapper';
import HomeInfo from 'components/cms/HomeContent/HomeInfo';
import HomeHeroStandalone from 'components/cms/HomeContent/HomeHeroStandalone';
import NewsletterSignUp from 'components/general/NewsletterBanner/NewsletterSignUp';
import SocialChannels from 'components/general/SocialChannels';
import SummaryScoreboard from 'appdir/components/_scoring/SummaryScoreboard';
import FilmStrip from 'components/cms/HomeContent/FilmStrip';
import KeyPoints from 'components/cms/HomeContent/KeyPoints';
import StoryGroup from 'components/cms/HomeContent/StoryGroup';
import SingleFeature from 'components/cms/HomeContent/SingleFeature';
import DoubleStack from 'components/cms/HomeContent/DoubleStack';
import ButtonGroup from 'components/cms/HomeContent/ButtonGroup';
import HomeFavorites from 'components/general/HomeFavorites';
import LiveBlog from 'components/cms/HomeContent/LiveBlog';
import { Helmet } from 'react-helmet';

/**
 * -----------------------------------------------------------------------------
 * React Component: Home
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['Home'],
		EventsWindow: state['WindowSize'].EventsWindow,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.Home.mount()),
	unmount: () => dispatch(deps.actions.Home.unmount()),

	// more: (start, count) => dispatch(deps.actions.Home.more(start, count)),
});

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {};

		// this.dateSelectCallback = this.dateSelectCallback.bind(this);
		// this.loadMoreCallback = this.loadMoreCallback.bind(this);

		this.dataLoaded = false;

		MeasurementUtils.dispatchMeasurementCall(MeasurementUtils.ACTION_TYPES.pageView, {
			pageTitle: 'Home',
		});
	}

	getDropDownList(daysList) {
		let list = [];
		daysList.map((day, index) => {
			index == 0 ? (list[index] = { name: 'Latest News', value: day.file }) : null;
			list[index + 1] = { name: day.displayDate, value: day.file };
		});
		return list;
	}

	componentWillUnmount() {
		// logger.log('[Home] componentWillUnmount -- do something');
		this.props.unmount();
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[Home] - componentDidUpdate state:%o', this.state);

		//if (this.props.homeData && this.dataLoaded == false) {
		if (prevProps.status !== this.props.status && this.props.status == 'loaded') {
			let pathsObj = [this.props.homeData, this.props.siteBG, this.props.geoCheck];

			fetchPromiseAll(pathsObj)
				.then(
					axios.spread((result, siteResults, geoResults) => {
						this.dataLoaded = true;
						let banner = result.modules.find(module => {
							// logger.log('[Home] - componentDidUpdate module:%o', module);

							return module.type == 'Banner';
						});

						this.setState({
							// heroData: result.promos[0],
							media_wall: result.media_wall,
							content: result.modules,
							banner: banner,
							bg_image: siteResults.backgroundImage,
							mobile_bg: siteResults.mobileBackgroundImage,
							rolex: result?.rolex ? result.rolex : 1,
							geo_target: geoResults?.target ? geoResults.target : 'US',
						});
					})
				)
				.catch(error => {
					logger.error('[Home] componentDidUpdate error:%o', error);
					this.dataLoaded = false;
				});

			// fetch(this.props.homeData.filterList)
			// 	.then(result => {
			// 		this.setState({
			// 			dateDropdownData: this.getDropDownList(result.days),
			// 		});
			// 	})
			// 	.catch(error => {
			// 		logger.error('[Home] componentDidUpdate error:%o', error);
			// 		this.dataLoaded = false;
			// 	});
		}
	}

	// dateSelectCallback(value) {
	// 	logger.log('[Home] dateSelectCallback value:%o', value);

	// 	fetch(this.state.jsonServer + value)
	// 		.then(result => {
	// 			this.setState({
	// 				heroData: result.promos[0],
	// 				media_wall: result.media_wall,
	// 				content: result.content,
	// 			});
	// 			MeasurementUtils.dispatchMeasurementCall('homeDateSelect', {
	// 				date: value,
	// 			});
	// 		})
	// 		.catch(error => {
	// 			logger.error('[Home] dateSelectCallback error:%o', error);
	// 		});
	// }

	// loadMoreCallback() {
	//   logger.log("[Home] loadMoreCallback -- do something");
	//   // this.props.more(this.state.end, 9);
	// }

	getMediaCard(item, i) {
		// logger.log('[Home]  - getMediaCard: %o', item);
		let windowWidth = this.props.EventsWindow.windowWidth;
		let headerSetting;
		// if (item.stacking_id) {
		// 	MeasurementUtils.dispatchMeasurementCall('HomeComponents', {
		// 		name: item.title,
		// 		type: item.type,
		// 		stacking_id: item.stacking_id,
		// 	});
		// }

		switch (item.type) {
			//new home components for 2022 - Looking for old components at NotUsed_components

			case 'NativeContent':
				return <NativeContent key={i} data={item} />;

			case 'FilmStrip':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<FilmStrip
							attributes={{
								data: item,
								key: i,
								type: 'FilmStrip',
							}}
						/>
					</HomeContentWrapper>
				);

			case 'OnCourt':
				headerSetting = true;
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: headerSetting }}>
						<OnCourt
							attributes={{
								data: item,
							}}
						/>
					</HomeContentWrapper>
				);

			case 'HomeHero':
				let { media_wall } = this.state ? this.state : false;

				if (!media_wall) {
					return (
						<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
							<HomeHeroStandalone
								data={{
									...item,
									EventsWindow: this.props.EventsWindow,
								}}
							/>
						</HomeContentWrapper>
					);
				} else {
					return null;
				}

			case 'HomeInfo':
			case 'HomeInfoLeft':
			case 'HomeInfoRight':
				headerSetting = false;

				if (
					item?.desktopPosition?.toLowerCase() !== 'sideleft' &&
					item?.desktopPosition?.toLowerCase() !== 'sideright'
				) {
					/** only when the content is placed in the middle col,
					 * show the header in the HomeInfo - assuming there is no sponsor to display
					 * and make the bulleted list to be two cols */

					headerSetting = true;
				}

				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: headerSetting }}>
						<HomeInfo
							data={{
								...item,
								showHeader: headerSetting,
								EventsWindow: this.props.EventsWindow,
							}}
						/>
					</HomeContentWrapper>
				);

			case 'NewsletterSignUp':
				return (
					<NewsletterSignUp
						attributes={{
							data: item,
							key: i,
						}}
					/>
				);

			case 'SocialChannels':
				return (
					<SocialChannels
						attributes={{
							data: item,
							key: i,
						}}
					/>
				);

			case 'SummaryScoreboard':
				/** because sum scores could be set to be hidden within the component,
				 * add <HomeContentWrapper /> in <SummaryScoreboard /> so title can be also hidden
				 */
				return <SummaryScoreboard data={{ ...item, suppressHeader: true, borderTop: 'red' }} />;

			case 'Keypoints':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<KeyPoints data={item} />
					</HomeContentWrapper>
				);

			case 'StoryGroup':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<StoryGroup data={item} />
					</HomeContentWrapper>
				);

			case 'SingleFeature':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<SingleFeature data={item} />
					</HomeContentWrapper>
				);

			case 'DoubleStack':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false, sponsor: item.sponsor }}>
						<DoubleStack data={item} />
					</HomeContentWrapper>
				);

			case 'ButtonGroup':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
						<ButtonGroup data={item} />
					</HomeContentWrapper>
				);

			case 'Favorites':
				return (
					<ErrorBoundary message="">
						<HomeContentWrapper data={{ ...item, suppressHeader: true }}>
							<HomeFavorites status={this.props.status} data={item} />
						</HomeContentWrapper>
					</ErrorBoundary>
				);

			case 'Minutely':
				return (
					<div
						className={item.id === 'minutely_two' ? `minute-tv-two` : `minute-tv`}
						data-embed-name="homepage"></div>
				);

			case 'LiveBlog':
				return (
					<HomeContentWrapper data={{ ...item, suppressHeader: false }}>
						<LiveBlog
							data={{
								...item,
								EventsWindow: this.props.EventsWindow,
							}}
						/>
					</HomeContentWrapper>
				);

			default:
				return null;
		}
	}

	/** three columns layout - used for above 1280px breakpoints
	 *  two columns layout --- above 1024px
	 *  one column layout ---- mobile
	 */
	renderContents = () => {
		let content = [];

		if (this.state.geo_target.toLowerCase() === 'tri-state') {
			//show all content except home hero with non tri-state location
			let tHomeHero = this.state?.content.find(item => {
				return item.type.toLowerCase() == 'homehero' &&  item.location.toLowerCase() == 'tri-state';
			}) || null;

			let uHomeHero = this.state?.content.find(item => {
				return item.type.toLowerCase() == 'homehero' &&  item.location.toLowerCase() == 'us';
			}) || null;

			let gHomeHero = this.state?.content.find(item => {
				return item.type.toLowerCase() == 'homehero' &&  item.location.toLowerCase() == 'global';
			}) || null;

			if(tHomeHero) {
				content.push(tHomeHero);
			}
			/** if no tri-state homeHero, try us homeHero */
			if(!tHomeHero && uHomeHero) {
				content.push(uHomeHero)
			}
			/** if no tri-state homeHero and no us homeHero, use global homeHero */
			if(!tHomeHero && !uHomeHero && gHomeHero) {
				content.push(gHomeHero);
			}

			this.state.content?.map(item => {
				/** if home hero and no location, and no home hero for other locations */
				if(item.type?.toLowerCase() == 'homehero' && !tHomeHero && !uHomeHero && !gHomeHero) {
					content.push(item);
				} else if (item.type?.toLowerCase() !== 'homehero') {
					content.push(item);
				}
			});
		} else {
			this.state.content?.map(item => {
				// filter show us or global
				if (item?.location?.toLowerCase() === this.state.geo_target.toLowerCase()) {
					content.push(item);
				} else if (item?.location?.toLowerCase() === 'global') {
					//add global
					content.push(item);
				} else if (!item?.location) {
					//if location does not exist add content
					content.push(item);
				}
			});
		}
		logger.log(
			'[Home] renderContents - this:%o, content:%o, geo_target:%o',
			this,
			content,
			this.state.geo_target.toLowerCase()
		);

		let { rolex } = this.state ? this.state : 1;
		let leftSideContent = content?.filter(item => item?.desktopPosition?.toLowerCase() === 'sideleft');
		let rightSideContent = content?.filter(item => item?.desktopPosition?.toLowerCase() === 'sideright');

		let showThreeCols = this.props?.EventsWindow?.numericWindowSize > 4;
		let showTwoCols = this.props?.EventsWindow?.numericWindowSize == 4;
		let showOneCol = this.props?.EventsWindow?.numericWindowSize < 4;

		/** filter the main content (center col) based on the screen size so ad can be inserted at the right spot */
		let mainContent = showOneCol
			? content
			: showTwoCols
			? content?.filter(item => item?.desktopPosition?.toLowerCase() !== 'sideright')
			: content?.filter(
					item =>
						item?.desktopPosition?.toLowerCase() !== 'sideleft' &&
						item?.desktopPosition?.toLowerCase() !== 'sideright'
			  );

		return (
			<section className="content-body home">
				{/** over 1280px - show left rail */
				showThreeCols ? (
					<div className="left-col">
						{leftSideContent.map((item, i) => {
							return (
								<React.Fragment key={`fragment-main-col-${i}`}>
									{this.getMediaCard(item, i)}
								</React.Fragment>
							);
						})}
					</div>
				) : null}

				<div className="main-col">
					{/** [TODO] Ad placement */
					mainContent.map((item, i) => {
						return (
							<React.Fragment key={`fragment-main-col-${i}`}>
								{this.getMediaCard(item, i)}

								{/** show Rolex watch under the first component on the mobile size  */
								i == rolex && showOneCol ? <RolexWatch /> : null}

								{/** [TODO] - Check how this displays as it's not showing up right now - Ad placement */
								i % 3 == 2 && this.props?.adMode == 'tournament' && i != content.length ? (
									<div className="topAd">
										<AdUnitLazy
											key={`adunit-${i}`}
											id={`adunit-${i}`}
											data={{ adType: `homeLeader` }}
										/>
									</div>
								) : null}
							</React.Fragment>
						);
					})}
				</div>

				{/** over 1024px - show right rail */
				!showOneCol ? (
					<div className="right-col">
						{rightSideContent.map((item, i) => {
							return (
								<React.Fragment key={`fragment-right-col-${i}`}>
									{this.getMediaCard(item, i)}
									{i == 0 ? <RolexWatch /> : null}
								</React.Fragment>
							);
						})}
					</div>
				) : null}
			</section>
		);
	};

	render() {
		// logger.log('[Home] render this:%o', this);

		let { heroData } = this.state ? this.state : null;
		let { content } = this.state ? this.state : null;
		let { media_wall } = this.state ? this.state : false;

		let header_propsData = {
			headerType: 'home',
			metaDescription:
				'The official site of the 2024 US Open Tennis Championships. Follow the action with live scoring, live radio, news and highlights. Buy tickets and plan your visit.',
			canonicalLink: 'https://www.usopen.org/index.html',
		};

		return (
			<Template header={header_propsData} subHeader={false} adType={'homehero'} adTypeRefresh={'homehero'}>
				<Helmet>
					<meta property="og:type" content="website" />
					<meta
						property="og:title"
						content="Official Site of the 2024 US Open Tennis Championships - A USTA Event"
					/>
					<meta property="og:description" content={header_propsData.metaDescription} />
					<meta property="fb:app_id" content="471867639491882" />
					<meta property="og:locale" content="en_US" />
					<meta property="og:url" content={header_propsData.canonicalLink} />
					<meta
						property="og:image"
						content="https://photo-assets.usopen.org/images/pics/large/c_2022-Ticket-depostis-2048x1152.jpg"
					/>
					<meta
						name="twitter:image"
						content="https://photo-assets.usopen.org/images/pics/large/c_2022-Ticket-depostis-2048x1152.jpg"
					/>
				</Helmet>
				<section className="wrapper homepage">
					<div className="page-wrap">
						<div className="content-wrap">
							<div className="subPageContent">
								{media_wall ? (
									<React.Fragment>
										<HomeHero />
										<RolexWatch />
									</React.Fragment>
								) : null}
								{content ? (
									<section className="page-content">
										{content.length > 0 ? (
											<section>
												{this.renderContents()}
												{/* {content.map((item, i) => {
													{
														return (
															<React.Fragment key={`fragment-${i}`}>
																{content.length - 1 === i ? (
																	<div
																		className="minute-tv"
																		data-embed-name="homepage"></div>
																) : null}
																{i % 4 == 0 && i != content.length && i != 0 ? (
																	<div className="topAd">
																		<AdUnitLazy
																			key={`adunit-${i}`}
																			id={`adunit-${i}`}
																			data={{ adType: `homeLeader` }}
																		/>
																	</div>
																) : null}
																{this.getMediaCard(item, i)}
																{i == 0 && !media_wall ? <RolexWatch /> : null}
															</React.Fragment>
														);
													}
												})} */}
											</section>
										) : null}
									</section>
								) : null}
							</div>
						</div>
					</div>
					{/* <div className="topAd">
						<AdUnitLazy key={`adunit-end`} id={`adunit-end`} data={{ adType: `homeLeader` }} />
					</div> */}
				</section>
			</Template>
		);
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(Home);
