/**
 * -----------------------------------------------------------------------------
 * Imports
 * -----------------------------------------------------------------------------
 */
import React, { Component } from 'react';
import { connect } from 'react-redux';

import deps from 'dependencies';
import classNames from 'classnames';
import Time from 'appdir/components/common-ui/Time';
import NewFlag from 'appdir/components/common-ui/NewFlag';
// import VideoPlayer from 'appdir/components/content/VideoPlayer';
import { getAppLink, getAppVideoLink } from 'appdir/components/general/Util';

const mapStateToProps = (state, props) => {
	return {
		...state['NewsTile'],
		newsTileDisplayConfig: state['Config']?.newsTileDisplayConfig,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	play: data => dispatch(deps.actions.VideoPlayer.play(data)),
	navigate: data => dispatch(deps.actions.MainNav.navigate(data)),
	loadOverlayModal: (type, data) => dispatch(deps.actions.ModalOverlay.loadOverlayModal(type, data)),
});

/**
 * uses a prop of attributes for data
 * attributes: {
 *   col-style: "one-col",
 *   contentId: "5769779656001",
 *   date: 1523631046799,
 *   images:{
 *     small: "http://wimbledonprogressivedl.edgesuite.net/2014/t…76584001_5769779656001-vs.jpg?pubId=3506358525001", medium: "http://wimbledonprogressivedl.edgesuite.net/2014/t…76584001_5769779656001-vs.jpg?pubId=3506358525001", large: "http://wimbledonprogressivedl.edgesuite.net/2014/t…76584001_5769779656001-vs.jpg?pubId=3506358525001"
 *   }
 *   shortTitle: "Throwback Thursday: The luckiest Wimbledon shot ever?",
 *   title: "Throwback Thursday: The luckiest Wimbledon shot ever?"
 *   type: "video"
 *   url: "/en_US/news/video/5769779656001.html"
 * }
 */

class NewsTile extends Component {
	constructor(props) {
		super(props);

		this.onVideoEvent = this.onVideoEvent.bind(this);
		this.onHover = this.onHover.bind(this);

		let attributes = props['attributes'];
		attributes['type'] = attributes['type'].toLowerCase();

		this.state = {
			onClick: props.onClick,
			...attributes,
			isPlayerRelated: props?.playerPage === true ? true : false,
			showModal: false,
			isHovering: false,
		};
		// logger.log('[NewsTile] - this: %o', this);
	}

	componentDidUpdate(prevProps) {
		// logger.log('[NewsTile] - componentDidUpdate: %o', this.state);

		if (prevProps.data?.id !== this.props?.data?.id) {
			this.setState(prevState => {
				return {
					...prevState,
					showModal: true,
				};
			});
		}
	}

	onVideoEvent(event) {
		// logger.info('[NewsTile] onVideoEvent - event:%o', event);

		if (event == 'close') {
			this.setState({
				videoId: '',
			});
		}
	}

	handleMouseIn = () => {
		if (!this.state.showModal) {
			this.setState({
				isHovering: true,
			});
		} else {
			this.setState({
				isHovering: false,
			});
		}
	};

	handleMouseOut = () => {
		this.setState({ isHovering: false });
	};

	showVideoModal = () => {
		let videoData = {
			video: {
				...this.props?.data,
				autoplay: true,
				playsinline: true
			},
		};

		if(!window.webview) {
			this.props.loadOverlayModal('VIDEO_MODAL', videoData )
		} else {
			if(this.props?.isPanel === true) {
				if (window.webviewPlatform == 'ios') {
					window.webkit.messageHandlers.video.postMessage(this.props?.data?.id);
				} else {
					//logger.log('http://usopen/video?id=' + videoData.id)
					location.href = 'http://usopen/video?id=' + (this.props?.data?.id);
				}
			} else {
				let videoLink = getAppVideoLink(this.props?.data?.id);
				videoLink?.onclick();
			}
		}
	}

	/**
	 * return videoplayer if have current videoId
	 *   videoId set by click on news tile
	 */
	getVideo() {
		if (this.state.videoId) {
			//logger.log('[NewsTile] getVideo - state:%o', this.state);
			let attributes = {
				playerId: 'main',
				contentId: this.state.contentId,
				videoUrl: this.state.videoUrl,
				title: this.state.title,
				thumb: this.state.thumb,
				date: this.state.date,
				shareUrl: this.state.shareUrl,
				adUrl: this.state.adUrl,
				nextUp: this.state.nextUp,
				autoplay: true,
				fullscreenMode: '',
				style: `video-wrapper modal ${this.state['col-style']}`,
				aspect: this.state.hasOwnProperty('aspect') ? this.state.aspect : '',
			};

			return (
				// <VideoPlayer attributes={attributes} onEvent={this.onVideoEvent} />
				null
			);
		} else {
			return null;
		}
	}

	/**
	 * handle clicks from news tile
	 */
	onClick(playerRelatedPhoto) {
		logger.log('[NewsTile] onClick - this:%o', this);

		if(playerRelatedPhoto && playerRelatedPhoto === true) {
			return null;
		}

		if (this.props?.onClick) {
			this.props.onClick();
			if (this.props.attributes.type == 'video') {
				this.showVideoModal();
			}
			return;
		}

		if (this.props.attributes.type == 'video') {
			//this.props.play(this.state.contentId);
			this.showVideoModal();
			this.setState({
				videoId: this.props.attributes.id,
			});
		} else {
			// logger.log('[NewsTile] onClick - url:%o', this.props.attributes.prefix + this.props.attributes.url);

			if (!window.webview) {
				if (this.props.attributes.prefix && this.props.attributes.prefix.indexOf('usopen.org') == -1) {
					window.location.assign(this.props.attributes.prefix + this.props.attributes.url);
					// this.props.navigate({ path: `${this.props.attributes.prefix}${this.props.attributes.url}` });
				} else {
					// window.location.assign(this.props.attributes.url);
					this.props.navigate({ path: this.props.attributes.url, panelClick: this.props.isPanel });
				}
			} else {
				// logger.log('[NewsTile] onClick - attributes:%o', this.props.attributes);
				let fullLink;

				if (this.props.attributes.prefix) {
					fullLink = this.props.attributes.prefix + this.props.attributes.url;
				} else {
					fullLink = this.props.attributes.url;
				}

				// logger.log('[NewsTile] onClick - fullLink:%o', fullLink);

				let appLink = getAppLink(fullLink);

				// logger.log('[NewsTile] onClick - appLink:%o', appLink);

				if (!appLink.external) {
					logger.log('[NewsTile] onClick - appLink.external:%o', appLink.external);
					document.location.href = appLink.url;
				}
			}
		}
	}

	onHover(hovering) {
		if (this.props.mouseOverCaptions) {
			this.setState({ hovering });
		}
	}

	getDisplayCategory() {
		// logger.log('[NewsTile] getDisplayCategory - this:%o', this);
		let { newsTileDisplayConfig } = this.props;
		let type = this.state?.type;
		let subType = this.state?.subType;
		let displayCategory = this.state?.metadata?.displayCategory ? this.state.metadata.displayCategory : null;
		let newDisplayCategory = '';

		if (this.props?.categoryFilter === true) {
			newDisplayCategory =
				type == 'news'
					? subType == 'photolist'
						? newsTileDisplayConfig[subType].default
						: newsTileDisplayConfig[type].displayCategories.includes(displayCategory)
						? displayCategory
						: newsTileDisplayConfig[type].default
					: subType.replace('_', ' ');
		} else {
			newDisplayCategory = displayCategory
				? displayCategory.replace('_', ' ')
				: type == 'news'
				? 'NEWS'
				: subType.replace('_', ' ');
		}

		return newDisplayCategory;
	}

	render() {
		//background image style
		// <div className="image-container" style={{ backgroundImage: `url(${attributes["images"]["small"]})` }} >

		// let pathPrefix = this.state.prefix != null ? this.state.prefix : "";
		let pathPrefix = '';

		//interviews does not need a path prefix like news and photos does
		if (this.props.subtype === 'interviews') {
			pathPrefix = '';
		}

		logger.log('[NewsTile] render - this:%o', this.props);

		let aspect = this.props.hasOwnProperty('aspect') ? this.props.aspect : '';

		let tileClasses = classNames('news-tile', this.props.new, this.props.type + '-tag', this.props['col-style']);

		let duration = '';
		if (this.state.duration && this.state.duration != '') {
			//logger.log('[NewsTile] render - duration:%o', this.state.duration);
			duration = <div className="duration-tag">{this.state.duration}</div>;
		}

		//if (this.state.type.toLowerCase() !== 'video') {
		//logger.log('[NewsTile render - not video');
		var minute = {};
		minute['share-link'] = this.props?.data?.shareUrl;

		// logger.log('[NewsTile render - videoData:%o', this.props?.data);

		return (
			<div
				className={`chip ${this.state.type}-chip ${
					this.state.isHovering && !this.state.showModal ? 'hovering' : ''
				}`}
				id={this.props.attributes.id}
				onClick={() => this.onClick(this.state.isPlayerRelated)}
				onMouseMove={this.handleMouseIn}
				onMouseOut={this.handleMouseOut}>
				{/* onMouseOver={() => this.onHover(true)}
			     onMouseOut={() => this.onHover(false)} */}
				<div className="photoContainer" {...(this.state?.type === 'video' && { ...minute })}>
					{typeof this.state.images !== 'string' ? (
						<img
							// className="photoContainer"
							src={
								this.state.images
									? pathPrefix +
									  `${
											this.state.type == 'photo'
												? this.state.images.large
												: this.state.images.medium
									  }`
									: pathPrefix + this.state.thumbURL ||
									  pathPrefix + this.state.thumb ||
									  pathPrefix + this.state.img
							}
							alt={this.state.title}
						/>
					) : (
						<img src={this.state.images ? pathPrefix + this.state.images : ''} />
					)}
					{this.state.subtype === 'interviews' && <div className="playBtn">&nbsp;</div>}
					{this.state.type === 'video' ? <i className="icon-play-button" /> : null}
				</div>

				{this.state.shortTitle && (
					<div className={`caption ${this.state.hovering ? `visible` : null}`}>{this.state.shortTitle}</div>
				)}
				{(this.state.type === 'news' || this.state.type === 'photo') && (
					<div className="credit">{this.state.author ? this.state.author : ''}</div>
				)}
				<div className="date">{<Time epoch_ms={this.state.date} format="dddd, MMMM D" />}</div>
				<NewFlag
					data={{
						url: this.state.url,
						contentId: this.state.contentId,
						displayDate: this.state.date,
					}}
				/>
				{this.state.type === 'video' ? duration : null}
				{/* <div className="type-tag">{this.state.type}</div> */}
				{this.props?.hideFooter ? null : (
					<div className="tile-footer">
						{/* awt:  they want all news to say news and not use the subtype.  go figure */}
						<div className="subtype">{this.getDisplayCategory()}</div>
						{this.state.type == 'video' ? (
							<i className="icon-blogIcon-video" />
						) : this.state.type == 'photo' ? (
							<i className="icon-photo" />
						) : this.state.type == 'news' ? (
							<i className="icon-news" />
						) : null}
						{/* <div className={`${this.state.type}-icon`}></div> */}
					</div>
				)}
			</div>
		);
		// } else {
		// 	logger.log('[NewsTile render - else');
		// 	return (
		// 		<span className={tileClasses}>
		// 			<div id={this.state.contentId} onClick={() => this.onClick()}>
		// 				<div className={`image-container ${aspect}`}>
		// 					<img
		// 						src={
		// 							this.state.images
		// 								? pathPrefix + this.state.images.medium
		// 								: pathPrefix + this.state.thumbURL ||
		// 								  pathPrefix + this.state.thumb ||
		// 								  pathPrefix + this.state.img
		// 						}
		// 						alt={this.state.title}
		// 					/>
		// 					{this.state.type === 'video' ? <i className="icon-video" /> : null}
		// 				</div>
		// 				{/* <NewFlag
		// 					data={{
		// 						url: this.state.url,
		// 						contentId: this.state.contentId,
		// 						displayDate: this.state.date,
		// 					}}
		// 				/> */}
		// 				{this.state.type === 'video' ? duration : null}
		// 				{/* <div className="type-tag">{this.state.type}</div> */}
		// 				<div className="news-timestamp">
		// 					{<Time epoch_ms={this.state.date} format="ddd DD MMM YYYY kk:mm z" />}
		// 				</div>
		// 				<div className="news-title">{this.state.shortTitle}</div>
		// 			</div>
		// 			{this.getVideo()}
		// 		</span>
		// 	);
		// }
	}
}

export default connect(mapStateToProps, mapDispatchToProps)(NewsTile);
