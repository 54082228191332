import { connect } from 'react-redux';
import React, { Component } from 'react';
import { fetch } from 'appdir/components/general/Util';
import BlueBarDropdown from 'appdir/components/common-ui/BlueBarDropdown';
import { withRouter } from 'react-router-dom';

/**
 * -----------------------------------------------------------------------------
 * React Component: BlueBar
 *
 * props:
 * currentSelected - 2 character event code
 * handleOptionSelected - function to be called when user selects event
 * -----------------------------------------------------------------------------
 */
const mapStateToProps = (state, props) => {
	return {
		...state['BlueBar'],
		drawsData: state['Config']?.scoringData.draws,
		tourn_events: state['Config']?.scoringConfig?.eventNames,
		...props,
	};
};
class BlueBar extends Component {
	constructor(props) {
		super();
		// this.state = { selectedOption: null };
		// this.getItems = this.getItems.bind(this);
		this.isMobile = false;

		// logger.log('[BlueBar] constructor - this:%o', this);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.getItems);
	}

	/**
	 * @returns the number of events visible in the blue
	 * bar based on the window screen size
	 */
	displayByScreenSize() {
		let windowWidth = window.innerWidth;

		let sliceVal = 0;
		if (windowWidth <= 500) {
			sliceVal = 1;
			this.isMobile = true;
		} else if (windowWidth >= 500 && windowWidth < 768) {
			sliceVal = 2;
			this.isMobile = false;
		} else if (windowWidth >= 768 && windowWidth < 1024) {
			sliceVal = 4;
			this.isMobile = false;
		} else if (windowWidth >= 1024 && windowWidth < 1280) {
			sliceVal = 5;
			this.isMobile = false;
		} else if (windowWidth > 1280) {
			sliceVal = 7;
			this.isMobile = false;
		}
		return sliceVal;
	}

	parseData(dataArr) {
		let myItems = [];
		if (this.props.allEvents) {
			let allEvents = { id: 'AE', title: 'All Events', eventCode: '' };
			myItems.push(allEvents);
		}
		myItems = myItems.concat(dataArr);
		myItems = JSON.stringify(myItems);
		// instead of stringify here, before the data is passed in, stringify and
		// parse so that the values are mapped to id and title already

		myItems = JSON.parse(myItems);
		const sliceVal = this.displayByScreenSize();
		let expandedItems = myItems.slice(0, sliceVal); // events visible within blue bar
		let dropdownItems = myItems.slice(sliceVal); // events in dropdown
		return [expandedItems, dropdownItems];
	}

	getItems = () => {
		// logger.log('[BlueBar] getItems - this:%o', this);
		let items = [];
		items = this.parseData(this.state.drawsData);
		let expandedItems = items[0];
		let ditems = items[1];
		let dropdownItems = this.isMobile ? items[0].concat(items[1]) : ditems;
		let updated_dropdownItems = [];

		dropdownItems.map(d => {
			//get updated eventcode for dropdown items and set new array
			let newID = this.getEventCode(d.id);
			d.eventCode = newID;
			updated_dropdownItems.push(d);
		});

		this.setState({
			expandedItems: expandedItems,
			dropdownItems: updated_dropdownItems,
		});
	};

	displayVal(currentSelection) {
		let displayIndex;
		let displayVal;

		if (currentSelection) {
			/** attempt to get the title using the eventCode */
			displayIndex = this.state?.dropdownItems.filter(item => item.eventCode === currentSelection);

			/** if using the eventCode didn't work, try the id, because eventCode doesn't work for All Events (AE) */
			if (displayIndex.length == 0) {
				displayIndex = this.state?.dropdownItems.filter(item => item.id === currentSelection);
			}

			displayVal = displayIndex[0].title;

			return displayVal;
		}
		return null;
	}

	componentDidMount() {
		window.addEventListener('resize', this.getItems);
		if (this.props.drawsData) {
			fetch(this.props.drawsData)
				.then(result => {
					let initWidth = window.innerWidth;
					this.setState(
						{
							drawsData: result.draws,
						},
						() => {
							this.getItems(initWidth);
						}
					);
				})
				.catch(error => {
					this.setState({
						status: 'error',
						errorType: error,
					});
				});
		}
	}

	getEventCode(eventCode) {
		// logger.log('[BlueBar] getEventCode eventCode:%o, tourn_events:%o', eventCode, this.props.tourn_events);

		let newEventCode = '';

		this.props.tourn_events.map(item => {
			if (item.code == eventCode) {
				newEventCode = item.sb_code;
			}
		});

		// logger.log('[BlueBar] getEventCode newEventCode:%o', newEventCode);

		return newEventCode;
	}

	render() {
		// logger.log('[BlueBar] render - this:%o', this);
		const { currentSelection } = this.props;

		if (this.state?.expandedItems) {
			const result = this.state?.dropdownItems.find(
				// item => item.id === currentSelection || item.eventCode === currentSelection
				item => item.eventCode === this.getEventCode(currentSelection)
			);

			const res = result != null;
			const isDropdownEmpty = this.state?.dropdownItems.length === 0;

			return (
				<div className="blue-bar-wrapper">
					<div className={`events-wrapper ${isDropdownEmpty ? 'empty' : ''}`}>
						{this.state.expandedItems.map(e => {
							/** compare against the id or the eventCode */
							const isActive =
								(currentSelection === e.id || currentSelection === this.getEventCode(e.id)) && !res;
							const className = isActive || this.isMobile ? 'event-name active' : 'event-name';

							return (
								<li
									key={e.id}
									className={className}
									data-text={e.title}
									onClick={() => {
										this.props.handleOptionSelected(e, this.getEventCode(e.id), e.id);
									}}>
									{this.isMobile
										? currentSelection
											? this.displayVal(currentSelection)
											: e.title
										: e.title}
								</li>
							);
						})}
					</div>
					{/* SELECT DROPDOWN with browser default dropdown and onChange handler */}
					{!isDropdownEmpty ? (
						<BlueBarDropdown
							options={this.state.dropdownItems}
							onOptionSelected={this.props.handleOptionSelected}
							inDropdown={res}
							displayVal={currentSelection}
							isMobile={this.isMobile}
						/>
					) : null}
					{this.props?.showPdfIcon === true && this.props?.handlePdfClick ? (
						<div className="drawsPdf" onClick={this.props.handlePdfClick}>
							<i className="icon-pdf" />
						</div>
					) : null}
				</div>
			);
		} else return null;
	}
}

export default withRouter(connect(mapStateToProps, null)(BlueBar));
