import React, { Component } from 'react';
import { connect } from 'react-redux';
import deps from 'dependencies';
import axios from 'axios';
import { fetchPromiseAll } from 'appdir/components/general/Util';
import find from 'lodash/find';
import split from 'lodash/split';
import isEqual from 'lodash/isEqual';
import EventsLink from 'appdir/components/general/EventsLink';
import EventsRedirect from 'appdir/components/general/EventsRedirect';
import Favorites from 'appdir/components/common-ui/Favorites';
import FavoritesModule from 'appdir/components/general/FavoritesModule';
import DrawsSub from 'appdir/components/pages/DrawsPage/DrawsSub';
import ErrorBoundary from 'appdir/components/general/ErrorBoundary';
import GenericError from 'appdir/components/general/ErrorBoundary/GenericError';
import BlueBar from 'appdir/components/common-ui/BlueBar';
import { withRouter } from 'react-router-dom';
import BlueBarMenu from 'appdir/components/common-ui/BlueBarMenu';
import TournamentDaySelector from 'appdir/components/common-ui/TournamentDaySelector';
import MeasurementUtils from 'appdir/lib/analytics';

/**
 * -----------------------------------------------------------------------------
 * React Component: PageSubMenu
 *
 * props:
 * mode - main menu item for page
 * selected - selected submenu item(usually the corresponding page)
 * filters - redux state values needed for page (ex - filters: {day: '1', open: ''})
 * showFavorites - {bool} ----- if true display My Favorites (<Favorites>)
 * playersData --- {obj} ------ players obj that are available to search on draws & schedule page
 * searchedPlayer - player.id - searchable page, Draws and Schedule to pass which player it is
 * onClick -------- func ------ callback func(), set searchedPlayer local state on the parent page
 * enableFuture - {bool} ---- if true all future days are clickable for schedule page
 * -----------------------------------------------------------------------------
 */

const mapStateToProps = (state, props) => {
	return {
		...state['PageSubMenu'],
		resultsPage: state?.['ResultsPage'],
		schedulePage: state?.['SchedulePage'],
		drawsPage: state?.['DrawsPage'],
		menuItems: state?.['Controller']?.menuItems ?? null,
		currentDay: state?.['ActiveData']?.currentDay ?? {},
		favorites: state?.['Controller']?.favorites ?? {},
		EventsWindow: state?.['WindowSize']?.EventsWindow ?? {},
		scoringConfig: state['Config']?.scoringConfig ?? {},
		ScoreManager: state?.['ScoreManager'],
		scoringData: state?.['Config']?.scoringData,
		scoring: state?.['Config']?.scoring,
		...props,
	};
};

const mapDispatchToProps = (dispatch, props) => ({
	mount: () => dispatch(deps.actions.PageSubMenu.mount()),
	unmount: () => dispatch(deps.actions.PageSubMenu.unmount()),
	filter: () => dispatch(deps.actions.PageSubMenu.filter()),
	resultsFilter: settings => dispatch(deps.actions.ResultsPage.filter(settings)),
	scheduleFilter: settings => dispatch(deps.actions.SchedulePage.filter(settings)),
	drawFilter: settings => dispatch(deps.actions.DrawsPage.filter(settings)),
});

class PageSubMenu extends Component {
	constructor(props) {
		super(props);

		this.state = {
			showFilter: this.props?.selected === 'Completed Matches' ? true : false,
			filters: props.filters,
		};
		this.dateChecked = false;
		this.loadedMenu = false;
		this.submenuLoaded = false;
		this.filterEventList = ['AE', 'WS', 'MS', 'MD', 'WD', 'XD', 'CS'];
		this.filterList = ['WS', 'MS', 'MD', 'WD', 'XD', 'MQ', 'WQ']; //for dropdown filter selection
		this.firstLoad = true;
		this.pageMode = null;
		logger.log('[PageSubMenu] Constructor props - props:%o', this);
	}

	componentDidMount() {
		this.props.mount();

		/**
		 * get menu data from where it's already loaded in controller, only
		 * need to set subMenuData into local state
		 */
		if (this.props?.menuItems && !this.submenuLoaded) {
			this.submenuLoaded = true;
			this.getSubMenuData();
		}
	}

	componentDidUpdate(prevProps, prevState) {
		// logger.log('[PageSubMenu] componentDidUpdate - this:%o', this);

		/**
		 * set this.pageMode value to remove white space
		 * like in fan week, and set mode to lowercase
		 */
		if (prevProps?.mode !== this.props?.mode || this.pageMode == null) {
			this.submenuLoaded = false;
			this.pageMode = this.props?.mode.toLowerCase().replace(/\s/g, '-');
		}
		/**
		 * we need to set selected schedule tab and call showFilterdetails
		 * when the page loads and when either currentDay.schedule changes
		 * or when schedulePage.filters.day changes
		 **/
		if (
			this.pageMode === 'schedule' &&
			this.state?.tourn_days &&
			(this.firstLoad ||
				!isEqual(this.props?.currentDay?.schedule, prevProps?.currentDay?.schedule) ||
				!isEqual(this.props?.schedulePage?.filters?.day, prevProps?.schedulePage?.filters?.day))
		) {
			this.firstLoad = false;

			this.setState(
				{
					/** RLL 8/26/23 added code to set next day to account for day 5 of quals */
					selectedScheduleTab:
						this.pageMode === 'schedule'
							? parseInt(this.props?.currentDay?.schedule) ===
							  parseInt(this.props?.schedulePage?.filters.day)
								? 'currentDay'
								: (this.props?.currentDay?.schedule === '5' &&
										parseInt(this.props?.currentDay?.schedule) + 2 ===
											parseInt(this.props?.schedulePage?.filters?.day)) ||
								  (this.props?.currentDay?.schedule !== '5' &&
										parseInt(this.props?.currentDay?.schedule) + 1 ===
											parseInt(this.props?.schedulePage?.filters?.day))
								? 'nextDay'
								: 'allDays'
							: 'allDays',
				},
				() => {
					this.showFilterDetails(true, this.state?.selectedScheduleTab);
				}
			);
		}

		/**
		 * get menu data from where it's already loaded in controller, only
		 * need to set subMenuData into local state - if this didn't happen in
		 * componentDidMount
		 */
		if (this.props?.menuItems && !this.submenuLoaded) {
			this.submenuLoaded = true;
			this.getSubMenuData();
		}
		if (
			this.pageMode === 'schedule' &&
			((!this.loadedMenu && this.props?.status == 'loaded') ||
				(prevProps.mode !== this.props?.mode && this.loadedMenu))
		) {
			this.fetchInfo();
		}
	}

	getSubMenuData() {
		this.submenuLoaded = true;

		let subMenu = find(this.props?.menuItems?.menu?.item, o => {
			return o.title?.toLowerCase() == this.props?.mode?.toLowerCase();
		});

		this.setState({
			subMenuData: subMenu?.sub_menu ? subMenu.sub_menu : null,
		});
	}

	componentWillUnmount() {
		// logger.log('[PageSubMenu] componentWillUnmount');
		this.loadedMenu = false;
		this.props.resultsFilter({ event: '', open: '' });
		this.props.unmount();
	}

	handleChange(evt) {
		this.setState({
			value: evt.target.value,
		});
	}

	onOpen(which) {}

	fetchInfo = () => {
		let daysPath = this.props?.daysPath;
		let practicePath = this.props?.practicePath;
		let pathsObj = [daysPath, practicePath];
		this.loadedMenu = true;

		// logger.log('[PageSubMenu] fetchInfo - pathsObj: %o', pathsObj);

		fetchPromiseAll(pathsObj)
			.then(
				axios.spread((daysResults, practiceResults) => {
					this.setState({
						tourn_days: daysResults.eventDays,
						practiceData: practiceResults,
					});
				})
			)
			.catch(error => {
				// logger.log('[PageSubMenu] fetchInfo error loading data: %o', error);
				this.setState({
					hasError: true,
					errorType: error,
				});
			});
	};

	showSub() {
		//show selected submenu & more filter details
		switch (this.pageMode) {
			case 'schedule':
				return this.showScheduleSub();

			case 'draws':
				return this.showDrawsSub();

			case 'scores':
				return this.showScoresSub();

			case 'players':
				return this.showPlayersSub();

			case 'news':
				return this.showNewsSub();

			case 'visit':
				return null;

			case 'fan-week':
				return null;

			default:
				return this.showScheduleSub();
		}
	}

	findDay(searchDate) {
		let thisDay = find(this.state?.tourn_days, d => {
			return d.tournDay == searchDate;
		});
		let displayDate = split(thisDay?.message, ', ');
		let hrefLink = split(thisDay?.url, 'webview');
		let selectedClass;
		if (this.state?.filters?.day) {
			selectedClass = thisDay?.tournDay == parseInt(this.state.filters.day) ? 'selected' : '';
		}

		let info = {
			displayDate: displayDate[1],
			hrefLink: hrefLink[1],
			selected: selectedClass,
			released: thisDay?.released,
		};

		// logger.log('[PageSubMenu] findDay - thisDay: %o, info: %o, searchDate: %o', thisDay, info, searchDate);
		return info;
	}

	showIcons() {
		let pdfURL = this.props?.pdfPath + parseInt(this.state?.filters?.day) + '.pdf';
		if (this.pageMode == 'draws') {
			pdfURL = this.props.drawPdf;
		} else if (this.pageMode == 'scores') {
			return null;
		}

		if (this.props.selected === 'Practice Schedule') {
			return null;
		} else {
			return (
				<div className="icons">
					<EventsLink to={pdfURL}>
						<i className="icon-pdf" />
					</EventsLink>
				</div>
			);
		}
	}

	showDrawsSub() {
		return <DrawsSub data={{ ...this.props.data }} />;
	}

	showScoresSub() {
		return (
			<div className="scores-sub">
				{this.props?.scoring?.showLiveScoresTab === true ? (
					<div
						className={`scores-sub-item ${this.props?.selected == 'Live Scores' ? 'selected' : ''}`}
						data-text="Live Matches">
						<EventsLink to="/en_US/scores/index.html?promo=subnav">
							{this.props?.scoring?.liveScoresText}
						</EventsLink>
					</div>
				) : null}
				<div
					className={`scores-sub-item ${this.props?.selected == 'Completed Matches' ? 'selected' : ''}`}
					data-text="Completed Matches">
					<EventsLink to="/en_US/scores/completed_matches/index.html?promo=subnav">
						Completed Matches
					</EventsLink>
				</div>
			</div>
		);
	}

	showPlayersSub() {
		return null;
	}

	// showFanWeekSub() {
	// 	/**
	// 	 * search to see if selected item has third_menu to display, if so
	// 	 * return menu, if not, return null
	// 	 */
	// 	let thirdMenu =
	// 		find(this.state.subMenuData, o => {
	// 			return o.title === this.props?.selected;
	// 		})?.third_menu || null;

	// 	if (thirdMenu) {
	// 		let displayList = [];
	// 		let _this = this;

	// 		thirdMenu.map((item, i) => {
	// 			let selectedClass = item.link.url === window.location.pathname ? 'selected' : '';

	// 			displayList.push(
	// 				<div
	// 					data-text={item.title}
	// 					className={`item ${selectedClass}`}
	// 					key={`${item.title} ${_this.props.mode} ${i}`}>
	// 					<EventsLink to={item.link.url}>{item.title}</EventsLink>
	// 				</div>
	// 			);
	// 		});

	// 		return <div className="shows">{displayList}</div>;
	// 	} else {
	// 		return null;
	// 	}
	// }

	showNewsSub() {
		/**
		 * search to see if selected item has third_menu to display, if so
		 * return menu, if not, return null
		 */
		let thirdMenu =
			find(this.state?.subMenuData, o => {
				return o.title === this.props?.selected;
			})?.third_menu || null;

		if (thirdMenu) {
			let displayList = [];
			let _this = this;

			thirdMenu.map((item, i) => {
				let selectedClass = item.link.url === window.location.pathname ? 'selected' : '';

				displayList.push(
					<div
						data-text={item.title}
						className={`item ${selectedClass}`}
						key={`${item.title} ${_this.subMode} ${i}`}>
						<EventsLink key={`l3-${displayList.length}`} to={item.link.url}>
							{item.title}
						</EventsLink>
					</div>
				);
			});

			return <div className="shows">{displayList}</div>;
		} else {
			return null;
		}
	}

	showScheduleSub() {
		if (this.props?.selected === 'TV Schedule') {
			let selectDom = this.props?.type == 'Domestic' ? 'selected ' : '';
			let selectIntl = this.props?.type == 'International' ? 'selected ' : '';
			let domInfo = {};
			let intInfo = {};
			let { menu } = this.props?.menuItems;

			//get third menu items
			let thirdMenu = find(menu.item, o => {
				if (o.title === 'Schedule') {
					o.third_menu?.map(t => {
						if (t.title.toLowerCase().includes('domestic')) {
							domInfo = {
								link: t.link.url,
								title: t.title,
							};
						} else if (t.title.toLowerCase().includes('international')) {
							intInfo = {
								link: t.link.url,
								title: t.title,
							};
						}
					});
					return o.third_menu;
				}
			});

			return (
				<div className="tv">
					<div className={`area ${selectDom}`}>
						<EventsLink to={domInfo.link}>{domInfo.title}</EventsLink>
					</div>
					<div className={`area ${selectIntl}`}>
						<EventsLink to={intInfo.link}>{intInfo.title}</EventsLink>
					</div>
				</div>
			);
		} else if (this.props?.selected === 'Schedule of Play') {
			//get today's date & tomorrow date
			let currentDayInt = parseInt(this.props?.currentDay?.schedule);
			// let nextDayInt = parseInt(this.props?.currentDay?.schedule) + 1;

			/** RLL 8/26/23 added code to set next day to be monday for day 5 of quals */
			let nextDayInt =
				currentDayInt === 5
					? parseInt(this.props?.currentDay?.schedule) + 2
					: parseInt(this.props?.currentDay?.schedule) + 1;

			let currentDay, nextDay;
			// logger.log(
			// 	'[PageSubMenu] showScheduleSub - selectAll: %o,showAllDays:%o, showFilter:%o',
			// 	nextDay,
			// 	this.props.filters?.showAllDays,
			// 	this.state.showFilter
			// );

			if (this.state?.tourn_days) {
				currentDay = this.findDay(currentDayInt);
				nextDay = this.findDay(nextDayInt);

				if (!nextDay.released && !nextDay.hrefLink) {
					nextDay.hrefLink = `/en_US/scores/schedule/schedule${nextDayInt}.html`;
				}
			}
			let { selectedScheduleTab } = this.state;
			let isCurrentDay = currentDayInt === parseInt(this.props?.schedulePage?.filters?.day) ? true : false;
			let isNextDay = nextDayInt === parseInt(this.props?.schedulePage?.filters?.day) ? true : false;
			// logger.log('[PageSubMenu] showScheduleSub - nextDay: %o', nextDay);
			// logger.log('[PageSubMenu] showScheduleSub - currentDay: %o', currentDay);
			// logger.log('[PageSubMenu] showScheduleSub - tourn_days: %o', this.state.tourn_days);
			return (
				<div className="dates">
					<div
						className={`days ${
							selectedScheduleTab === 'currentDay' &&
							currentDayInt === parseInt(this.props?.schedulePage?.filters.day)
								? 'selected'
								: ''
						}`}
						data-text={currentDay?.displayDate}>
						{isCurrentDay && selectedScheduleTab === 'currentDay' ? (
							currentDay?.displayDate
						) : (
							<EventsLink
								to={currentDay?.hrefLink}
								onClick={() => {
									this.showFilterDetails(false, 'currentDay');
								}}>
								{currentDay?.displayDate}
							</EventsLink>
						)}
					</div>

					{/* {nextDay?.released ? ( */}
					<div
						className={`days ${
							selectedScheduleTab === 'nextDay' &&
							nextDayInt === parseInt(this.props?.schedulePage?.filters?.day)
								? 'selected'
								: ''
						}`}
						data-text={nextDay?.displayDate}>
						{isNextDay && selectedScheduleTab === 'nextDay' ? (
							nextDay?.displayDate
						) : (
							<EventsLink
								to={nextDay?.hrefLink}
								onClick={() => {
									this.showFilterDetails(false, 'nextDay');
								}}>
								{nextDay?.displayDate}
							</EventsLink>
						)}
					</div>
					{/* ) : null} */}
					<div
						className={`all ${selectedScheduleTab === 'allDays' ? 'selected' : ''}`}
						onClick={() => this.showFilterDetails(true, 'allDays')}
						tabIndex={0}
						data-text="All Scheduled Days">
						All Scheduled Days
						<br />
					</div>
				</div>
			);
		} else if (this.props?.selected == 'Practice Schedule') {
			//Create links for today's and tomorrow's practice schedule
			let currentDay = {};
			let nextDay = {};

			if (this.props?.practiceScheduleDays) {
				if (this.props?.practiceScheduleDays[0]) {
					currentDay.hrefLink = `/en_US/scores/schedule/practice/practice${this.props?.practiceScheduleDays?.[0].displayDay}.html`;
					let tmp = split(this.props?.practiceScheduleDays?.[0].displayDate, ', ');
					currentDay.displayDate = tmp[0];
					currentDay.selected = this.props?.practiceScheduleDays?.[0].selectedPracticeDay;
				}
				if (this.props?.practiceScheduleDays[1]) {
					nextDay.hrefLink = `/en_US/scores/schedule/practice/practice${this.props?.practiceScheduleDays?.[1].displayDay}.html`;
					let tmp = split(this.props?.practiceScheduleDays?.[1].displayDate, ', ');
					nextDay.displayDate = tmp[0];
					nextDay.selected = this.props?.practiceScheduleDays?.[1].selectedPracticeDay;
				}
			}

			logger.log('[PageSubMenu] showScheduleSub: currentDay: %o, nextDay: %o', currentDay, nextDay);

			return (
				<div className="dates">
					<div
						className={`days ${currentDay.selected === true ? 'selected' : ''}`}
						data-text={currentDay?.displayDate}>
						{currentDay.selected === true ? (
							currentDay?.displayDate
						) : (
							<EventsLink to={currentDay?.hrefLink}>{currentDay?.displayDate}</EventsLink>
						)}
					</div>

					<div
						className={`days ${nextDay?.selected === true ? 'selected' : ''}`}
						data-text={nextDay?.displayDate}>
						{nextDay?.selected == true ? (
							nextDay?.displayDate
						) : (
							<EventsLink to={nextDay?.hrefLink}>{nextDay?.displayDate}</EventsLink>
						)}
					</div>
				</div>
			);
		} else if (this.props?.selected == 'Media Landing') {
			let selectAll = this.state?.showFilter ? 'selected' : '';
			return (
				<div className="dates">
					<div className={`days ${currentDay?.selected}`}>
						<EventsLink to={currentDay?.hrefLink}>
							<div className="label">2022 Tournament</div>
						</EventsLink>
					</div>
					<div className={`days ${nextDay?.selected}`}>
						<EventsLink to={nextDay?.hrefLink}>
							<div className="label">By Year and Month</div>
						</EventsLink>
					</div>
				</div>
			);
		} else {
			let practiceInfo = split(this.state?.practiceData?.displayDate, ', ');
			let practiceDay = practiceInfo[1];

			return (
				<div className="dates">
					{this.props.selected === 'Practice Schedule' ? (
						<div className="days selected">{practiceDay}</div>
					) : null}
					{this.props.selected === 'Tournament Schedule' ? (
						<div className="all selected">All Scheduled Days</div>
					) : null}
				</div>
			);
		}
	}

	showFilterDetails(showFilter, tab) {
		// logger.log('[PageSubMenu] showFilterDetails - showFilter: %o, tab:%o', showFilter, tab);

		// if the loaded page is not currentDay or nextDay then set the tab to all days and show dayselect
		this.setState({
			showFilter:
				(parseInt(this.props?.currentDay?.schedule) !== parseInt(this.props?.schedulePage?.filters.day) &&
					/** RLL 8/26/23 added code to set next day to account for day 5 of quals */
					this.props?.currentDay?.schedule === '5' &&
					parseInt(this.props?.currentDay?.schedule) + 2 !== parseInt(this.props.schedulePage?.filters.day) &&
					this.props?.currentDay?.schedule !== '5' &&
					parseInt(this.props?.currentDay?.schedule) + 1 !==
						parseInt(this.props.schedulePage?.filters.day)) ||
				// parseInt(this.props?.currentDay?.schedule) + 1 !==
				// 	parseInt(this.props.schedulePage?.filters.day)) ||
				tab === 'allDays'
					? true
					: false,
			filters: {
				showAllDays: true,
			},
			selectedScheduleTab: tab,
		});
	}

	setRedirectEvent = event => {
		// logger.log('[PageSubMenu] - setEvent event:%o', event);

		/** set nav state to redirect */
		let nav = '';
		let external = false;
		this.setState({
			selectedItem: event.target.value,
			nav: event.target.value,
			external,
			filters: {
				open: '',
			},
		});
	};

	onOpen(which) {
		// logger.log('[PageSubMenu] onOpen:%o', which);
		this.props.filter({
			open: which,
		});
	}
	//Trim Eventslist by screen size
	filterBySsize(arr) {
		let eventlist = arr;
		let windowWidth = this.props?.EventsWindow?.windowWidth;

		let arr2 = [];

		if (windowWidth <= 639) {
			arr2 = eventlist.slice(0, 1);
		} else if (windowWidth >= 640 && windowWidth <= 1023) {
			arr2 = eventlist.slice(0, 3);
		} else if (windowWidth >= 1024 && windowWidth <= 1279) {
			arr2 = eventlist.slice(0, 6);
		} else {
			arr2 = arr;
		}
		return arr2;
	}

	displayMobileSubmenu() {
		//get mobile menu display
		let menulist = [];
		let mobileMenuDisplay = [];
		let mobilePdfDisplay;
		let displayName = '';
		let breakpointNum = 3; // <- target less than 768px windowsize
		let strfilters = { event: this.props?.resultsPage?.filters?.event || '', open: '' };

		if (this.pageMode === 'players') {
			return this.displaySubmenu();
		} else {
			if (this.pageMode === 'schedule') {
				displayName = this.props.selected;
				menulist = {
					options: [{ name: 'All Schedules', value: '' }],
				};

				let templist = [];
				this.state.subMenuData.map(item => {
					templist.push({
						name: item.title,
						value: item.link.url,
						disabled: item.disabled || item.title === this.props.selected,
					});
				});
				menulist.options = menulist.options.concat(templist);

				mobileMenuDisplay.push(
					<div className="schedule-page-mobile-dropdown-wrapper">
						<select onChange={this.setRedirectEvent} className="schedule-dropdown-menu">
							{menulist.options.map(option => (
								<option key={option.name} value={option.value} disabled={option.disabled}>
									{option.name}
								</option>
							))}
						</select>
						<span className="news-menu-arrow" />
					</div>
				);
				if (this.pageMode === 'schedule' && this.props?.showPdfIcon === true && this.props?.schedulePdfPath) {
					mobilePdfDisplay = (
						<EventsLink to={this.props.schedulePdfPath} external={true} style="drawsPdf">
							<i className="icon-pdf" />
						</EventsLink>
					);
				}
			} else if (this.pageMode === 'draws' && !window.webview) {
				return (
					<BlueBar
						handleOptionSelected={this.handleDrawClick}
						currentSelection={this.props?.drawsPage?.filters?.event}
						handlePdfClick={this.handleDrawPdfClick}
						showPdfIcon={this.props?.showPdfIcon || false}
					/>
				);
			} else if (this.pageMode === 'scores') {
				return (
					<BlueBar
						handleOptionSelected={this.handleScoreClick}
						allEvents={true}
						currentSelection={this.props?.resultsPage?.filters?.event}
					/>
				);
			} else if (this.pageMode === 'news') {
				let selectTitle;
				displayName = this.props.selected;

				if (this.props.EventsWindow?.numericWindowSize < breakpointNum) {
					/** only when there is dropdown menu to display (less than tablet size) add the default first value */
					selectTitle = [{ name: 'Filter by category', value: 'Filter by category', disabled: true }];
				}

				let templist = [];

				/** only when the menu is displayed in dropdown (< 768px), set disabled to the selected menu
				 * so it won't be clickable
				 */

				this.state.subMenuData.map(item => {
					templist.push({
						name: item.title,
						value: item.link.url,
						disabled:
							this.props.EventsWindow?.numericWindowSize < breakpointNum && displayName == item.title,
					});
				});

				if (selectTitle) {
					menulist.options = selectTitle.concat(templist);
				} else {
					menulist.options = templist;
				}
				// makes the default checked option "Filter by category" in mobile
				menulist.options[0].disabled = false;
				mobileMenuDisplay.push(
					<div className="news-page-mobile-dropdown-wrapper">
						<select onChange={this.setRedirectEvent} className="news-dropdown-menu">
							{menulist.options.map(option => (
								<option key={option.name} value={option.value} disabled={option.disabled}>
									{option.name}
								</option>
							))}
						</select>
						<span className="news-menu-arrow" />
					</div>
				);
			} else {
				/** put a safenet that displays subnav list from the menu.json
				 *  mode: "Visit" uses this
				 */
				return this.displaySubmenu();
			}
			let subMenuDisplay = (
				<div className="page-submenu">
					<div className="events-wrapper mobile selected">{displayName}</div>
					<div className="dropdown-wrapper mobile">{mobileMenuDisplay}</div>
					{mobilePdfDisplay}
				</div>
			);
			return subMenuDisplay;
		}
	}

	handleScoreClick = (e, eventCode, id) => {
		this.props.resultsFilter({ event: eventCode, open: '' });
		this.setState({ selectedOption: eventCode }, () => {});
		MeasurementUtils.dispatchMeasurementCall(this.props.selected, {
			selectedEvent: id,
		});
	};

	// e is the the whole object, option is the object.id
	handleDrawClick = (e, option) => {
		let oldUrl = window.location.pathname;
		let { history } = this.props;
		const url = new URL(e?.url, location);
		let shortUrl = url.toString().split(url.host)[1];

		if (oldUrl !== shortUrl) {
			this.props.drawFilter({
				event: e.id,
			});
			history.push(shortUrl);
		} else {
			return;
		}
	};

	handleDrawPdfClick = () => {
		this.props.onPdfClick();
	};

	handlePlayerClick = (e, option) => {
		this.props?.playersByCountry === true;
		this.props.selectEventHandler(e.id);
	};

	displaySubmenu() {
		let subMenuDisplay = [];
		let _this = this;

		// logger.log('[PageSubMenu] displaySubmenu - this: %o', this);

		//schedule sub menu items
		if (this.pageMode === 'schedule' || this.pageMode === 'news') {
			let displayList = [];
			this.state.subMenuData.map((item, i) => {
				let pathname = window.location.pathname
					.split('/')
					.slice(0, -1)
					.join('/');
				let linkname = item.link.url
					.split('/')
					.slice(0, -1)
					.join('/');

				let selectedClass = '';

				if (
					(this.pageMode === 'schedule' &&
						(item.title === 'Schedule of Play' || item.title === 'Practice Schedule') &&
						pathname === linkname) ||
					(this.pageMode === 'watch' &&
						item.title === 'Live Streaming' &&
						this.props?.selected == item.title) ||
					(this.pageMode === 'news' && item.title === 'Podcast' && this.props?.selected == item.title) ||
					item.link.url === window.location.pathname
				) {
					selectedClass = 'active';
				}

				// let selectedClass =
				// this.pageMode === 'schedule' && item.title === 'Schedule of Play'
				// 	? pathname === linkname
				// 		? 'selected'
				// 		: ''
				// 	: item.link.url === window.location.pathname
				// 	? 'selected'
				// 	: '';

				if (selectedClass == 'active') {
					displayList.push(
						<div
							data-text={item.title}
							className={`blue-bar-menu-event unclickable ${selectedClass}`}
							key={`${item.title} ${_this.subMode} ${i}`}>
							{item.title}
						</div>
					);
				} else {
					displayList.push(
						<EventsLink
							key={`l1-${displayList.length}`}
							className="blue-bar-menu-event"
							to={item.link.url}
							onClick={() => {
								if (window.location.pathname.indexOf('en_US/video/index.html') > -1) {
									logger.log(
										'[PageSubMenu] displaySubmenu - pathname %o',
										window.location.pathname.indexOf('en_US/video/index.html')
									);
									window.dispatchEvent(new Event('ChangeWatchRouterDispatch'));
								}
							}}>
							<div
								data-text={item.title}
								className={`blue-bar-menu-event ${selectedClass}`}
								key={`${item.title} ${_this.subMode} ${i}`}>
								{item.title}
							</div>
						</EventsLink>
					);
				}
			});

			if (this.pageMode === 'schedule' && this.props?.showPdfIcon === true && this.props?.schedulePdfPath) {
				displayList.push(
					<EventsLink
						key={`l2-${displayList.length}`}
						to={this.props.schedulePdfPath}
						external={true}
						style="drawsPdf">
						<i className="icon-pdf" />
					</EventsLink>
				);
			}

			subMenuDisplay.push(
				<div key={`dm-${subMenuDisplay.length}`} className="blue-bar-menu-wrapper">
					{displayList}
				</div>
			);
		} else if (this.pageMode === 'visit' || this.pageMode === 'watch' || this.pageMode === 'fan-week') {
			return <BlueBarMenu menuData={this.state.subMenuData} pathName={this.props?.location?.pathname} mode={this.pageMode} />;
		} else if (this.pageMode === 'draws' && !window.webview) {
			return (
				<BlueBar
					handleOptionSelected={this.handleDrawClick}
					currentSelection={this.props?.drawsPage?.filters?.event}
					mode={this.pageMode}
					handlePdfClick={this.handleDrawPdfClick}
					showPdfIcon={this.props?.showPdfIcon || false}
				/>
			);
		} else if (this.pageMode === 'scores') {
			return (
				<BlueBar
					handleOptionSelected={this.handleScoreClick}
					allEvents={true}
					currentSelection={this.props?.resultsPage?.filters?.event}
				/>
			);
		} else if (this.pageMode === 'players') {
			return (
				<BlueBar
					handleOptionSelected={this.handlePlayerClick}
					allEvents={true}
					currentSelection={this.props?.selectedEvent}
				/>
			);
		}
		return subMenuDisplay;
	}

	onSelected(which) {
		if (this?.props?.onSelected) {
			this?.props?.onSelected(which);
		}
	}

	onOpen = which => {
		if (this?.props?.onOpen) {
			this?.props?.onOpen(which);
		}
	};

	onClose(which) {
		if (this?.props?.onClose) {
			this?.props?.onClose(which);
		}
	}

	setDay = day => {
		// logger.log('[SchedulePage][setDay] setDay:%o, showFilter:%o', day, this.state?.showFilter);
		const showAllDays = this.state?.showFilter ? this.state?.showFilter : false;

		if (this.pageMode === 'scores') {
			this.props.resultsFilter({
				day: parseInt(day),
				open: '',
				showAllDays: showAllDays,
				// changeUrl: false,
			});
		} else {
			this.props.scheduleFilter({
				day: day,
				open: '',
				showAllDays: showAllDays,
				// changeUrl: true,
			});
		}
	};

	render() {
		this.showFav = this.props?.favorites?.show ? true : false;
		this.showMobileMenu = this.props?.EventsWindow?.windowWidth < 768 ? true : false;
		// logger.log('[PageSubMenu] render -this: %o', this);
		const newsWithFilter =
			this.pageMode === 'news' &&
			(this.props.selected === 'Latest' || this.props.selected === 'News' || this.props.selected === 'Photos')
				? 'newsWithFilter'
				: 'newsWithoutFilter';

		if (this.state.nav) {
			return <EventsRedirect push to={this.state.nav} />;
		} else if (this.state.subMenuData) {
			return (
				<ErrorBoundary message="The page subnavigation is unavailable at this time.">
					{!this.state.hasError ? (
						<div className={`page-submenu-wrapper ${this.pageMode}-mode`}>
							{this.pageMode !== 'scores'
								? this.showMobileMenu
									? this.displayMobileSubmenu()
									: this.displaySubmenu()
								: null}

							{this.pageMode !== 'watch' && (
								<div className={`submenu-details-wrapper ${newsWithFilter}`}>
									{this.showMobileMenu ? (
										<div className="details mobile">
											<div className="mobile-submenu">{this.showSub()}</div>
										</div>
									) : (
										<div className="details">{this.showSub()}</div>
									)}
								</div>
							)}
							{this.pageMode === 'scores'
								? this.showMobileMenu
									? this.displayMobileSubmenu()
									: this.displaySubmenu()
								: null}
							{(this.pageMode === 'scores' ||
								(this.pageMode === 'schedule' && this.props.selected === 'Schedule of Play')) &&
							this.state?.showFilter &&
							this.props?.selected !== 'Live Scores' ? (
								// && this.props?.selected !== 'TV Schedule'
								<div className="daynav-wrapper">
									<div className="daynav scores-select-menu">
										<TournamentDaySelector
											pageMode={this.pageMode}
											handleOptionSelected={this.setDay}
											selectedDay={
												this.pageMode === 'schedule'
													? this.props?.schedulePage?.filters.day
													: this.props?.resultsPage?.filters?.day
											}
											enableFuture={this.props.enableFuture}
										/>
									</div>
								</div>
							) : null}
							<div className="pagesub-favorites">
								{this.props?.showFavorites ? (
									<Favorites
										className="page-submenu-favorites"
										unFavColor="white"
										favColor="yellow"
										playersData={this.props?.playersData}
									/>
								) : null}
							</div>
							<div className="page-submenu-filter-wrapper">
								{this.props?.showFavorites && this.showFav ? (
									<div className="favorites-details">
										<FavoritesModule
											drawsData={this.props?.data}
											selectedRound={this.props?.selectedRound || null}
											playersData={this.props?.playersData}
											searchedPlayer={this.props?.searchedPlayer}
											mode={this.pageMode}
											onClick={this.props.onClick}
											invalidPlayers={this.props?.invalidPlayers}
											tab={this.props?.selected}
											scheduleReleased={this.findDay(
												parseInt(this.props.schedulePage?.filters.day)
											)}
										/>
									</div>
								) : null}
							</div>
						</div>
					) : (
						<GenericError message="The page subnavigation is unavailable at this time." />
					)}
				</ErrorBoundary>
			);
		} else {
			return null;
		}
	}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PageSubMenu));
